import React from 'react';

const StatCard = ({ icon, value, label, valueColor = 'inherit', isPercentage = false }) => {
	return (
		<div className="w-full width-box flex flex-no-wrap gap-8">
			<div className="img-school">
				<img src={icon} alt={label} width={84} />
			</div>

			<div className="dashboard-count">
				<div className="count-num text-lg xl:text-4xl" style={{ color: valueColor }}>
					{isPercentage ? `${value}%` : value}
				</div>
				<p className="count-hd">{label}</p>
			</div>
		</div>
	);
};

export default StatCard;
